import { useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Accordion,
  Navbar,
  Card,
  Image,
} from "react-bootstrap";
import { Route, Link } from "react-router-dom";
import CategoryMenu from "../components/CategoryMenu";
import MenuItemList from "../components/MenuItemList";
import { useDispatch } from "react-redux";
import ShoppingCart from "./ShoppingCart";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBowlFood,
  faBreadSlice,
  faCoffee,
  faGlassWater,
  faIceCream,
  faPlateWheat,
} from "@fortawesome/free-solid-svg-icons";
import { addTable } from "../redux/cartSlice";

import { useParams } from "react-router-dom";
const CustomerMenu = () => {
  const { tableId } = useParams();
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [tablenNum, setTablenNum] = useState("");
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const isMounted = useRef(true);
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleButtonClick = () => {
    setButtonDisabled(true);
  };
  const handleButtonClick2 = () => {
    setButtonDisabled(false);
  };

  const handleConfirmOrder = () => {
    // Logic for confirming order
    setButtonsDisabled(true);
  };

  const handleMakeAnotherOrder = () => {
    // Logic for making another order
    setButtonsDisabled(false);
  };

  useEffect(() => {
    setTablenNum("T12");
    dispatch(addTable("T12"));

    const handleBeforeUnload = (event) => {
      // when page is refreshed, ask for confirmation when the order is not yet confirmed
      // it can get deleted
      const message =
        "Are you sure you want to refresh or leave page? Changes you made may not be saved";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Define your categories and menu items here.
  const categories = [
    "Hot Bevarages",
    "Breakfast",
    "Cold drinks",
    "Wraps and Pies",
    "Sweet Stuff",
  ];

  // Define your menu items for each category.
  const menuItems2 = {
    "Hot Bevarages": [
      {
        imageUrl:
          "https://media.istockphoto.com/id/1174632482/photo/side-view-of-hot-latte-coffee-with-latte-art-and-cinnamon-sticks-in-a-vintage-matt-black-cup.jpg?s=612x612&w=0&k=20&c=OfqjgWT4YhWajZOmaUmY-CoG3Xq5CNpuwSMtx6NLaTc=",
        name: "BLACK TEA HONEY",
        price: 20,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1128911931/photo/ginger-tea-with-lemon.jpg?s=612x612&w=0&k=20&c=JvInbD2Z4N2sZrgR3xH6i-rOlixE-adyMyWOIw8bD60=",
        name: "WATER, LEMON & GINGER",
        price: 15,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1174632482/photo/side-view-of-hot-latte-coffee-with-latte-art-and-cinnamon-sticks-in-a-vintage-matt-black-cup.jpg?s=612x612&w=0&k=20&c=OfqjgWT4YhWajZOmaUmY-CoG3Xq5CNpuwSMtx6NLaTc=",
        name: "BLACK TEA LEMON",
        price: 20,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1174632482/photo/side-view-of-hot-latte-coffee-with-latte-art-and-cinnamon-sticks-in-a-vintage-matt-black-cup.jpg?s=612x612&w=0&k=20&c=OfqjgWT4YhWajZOmaUmY-CoG3Xq5CNpuwSMtx6NLaTc=",
        name: "BLACK TEA GINGER",
        price: 20,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1152767411/photo/cup-of-coffee-latte-isolated-on-white-background-with-clipping-path.jpg?b=1&s=612x612&w=0&k=20&c=uVpKfo6XWUcehLqF8QKNo_CGfJfS5kgRBS3aTZ-Ibjk=",
        name: "WHITE TEA HONEY",
        price: 30,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1152767411/photo/cup-of-coffee-latte-isolated-on-white-background-with-clipping-path.jpg?b=1&s=612x612&w=0&k=20&c=uVpKfo6XWUcehLqF8QKNo_CGfJfS5kgRBS3aTZ-Ibjk=",
        name: "WHITE TEA LEMON ",
        price: 30,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/981824714/photo/hot-coffee-cappuccino-latte-art-in-jade-color-cup-isolated-on-white-background-clipping-path.jpg?s=612x612&w=0&k=20&c=fch3F-LGCL-uX0IAGKmDduD9es0qsKN0Bp2VoJ4SJpw=",
        name: "WHITE TEA GINGER",
        price: 30,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1152767411/photo/cup-of-coffee-latte-isolated-on-white-background-with-clipping-path.jpg?b=1&s=612x612&w=0&k=20&c=uVpKfo6XWUcehLqF8QKNo_CGfJfS5kgRBS3aTZ-Ibjk=",
        name: "Earl Grey Tea",
        price: 25,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1152767411/photo/cup-of-coffee-latte-isolated-on-white-background-with-clipping-path.jpg?b=1&s=612x612&w=0&k=20&c=uVpKfo6XWUcehLqF8QKNo_CGfJfS5kgRBS3aTZ-Ibjk=",
        name: "Masala Tea ",
        price: 25,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1152767411/photo/cup-of-coffee-latte-isolated-on-white-background-with-clipping-path.jpg?b=1&s=612x612&w=0&k=20&c=uVpKfo6XWUcehLqF8QKNo_CGfJfS5kgRBS3aTZ-Ibjk=",
        name: "Mint Tea",
        price: 25,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1152767411/photo/cup-of-coffee-latte-isolated-on-white-background-with-clipping-path.jpg?b=1&s=612x612&w=0&k=20&c=uVpKfo6XWUcehLqF8QKNo_CGfJfS5kgRBS3aTZ-Ibjk=",
        name: "Mochachino Hot Drink",
        price: 40,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1152767411/photo/cup-of-coffee-latte-isolated-on-white-background-with-clipping-path.jpg?b=1&s=612x612&w=0&k=20&c=uVpKfo6XWUcehLqF8QKNo_CGfJfS5kgRBS3aTZ-Ibjk=",
        name: "Latte (Vanilla Hazelnut) Hot Drink ",
        price: 40,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1152767411/photo/cup-of-coffee-latte-isolated-on-white-background-with-clipping-path.jpg?b=1&s=612x612&w=0&k=20&c=uVpKfo6XWUcehLqF8QKNo_CGfJfS5kgRBS3aTZ-Ibjk=",
        name: "Café Mocha Hot Drink ",
        price: 40,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1152767411/photo/cup-of-coffee-latte-isolated-on-white-background-with-clipping-path.jpg?b=1&s=612x612&w=0&k=20&c=uVpKfo6XWUcehLqF8QKNo_CGfJfS5kgRBS3aTZ-Ibjk=",
        name: "Café Latte",
        price: 30,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1152767411/photo/cup-of-coffee-latte-isolated-on-white-background-with-clipping-path.jpg?b=1&s=612x612&w=0&k=20&c=uVpKfo6XWUcehLqF8QKNo_CGfJfS5kgRBS3aTZ-Ibjk=",
        name: "Cappuccino Hot Drink ",
        price: 30,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/682380852/photo/takeaway-coffee-cup-with-clipping-path.jpg?s=612x612&w=0&k=20&c=5J2XuihC3L58BqtPqfO8USOTGgouxuXO-452KqtFol8=",
        name: "Take away cup ",
        price: 5,
        selectedQty: 0,
      },
    ],
    Breakfast: [
      {
        imageUrl:
          "https://media.istockphoto.com/id/182896744/photo/chicken-salad-sandwich.jpg?s=612x612&w=0&k=20&c=FPG4BiTfido68tBBDozWPAhK5ZYqqIxuL0gjEOrQBJw=",
        name: "Chicken & Mayonnaise",
        price: 75,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1010790712/photo/bowl-of-healthy-fresh-fruit-salad-isolated-on-white-background-top-view.jpg?s=612x612&w=0&k=20&c=iTzuOmdiWo0HGZzxHmS-aGwMnVXVrMb_53f4ApcY_0w=",
        name: "FRUIT BOWL BIG",
        price: 150,
        selectedQty: 0,
      },

      {
        imageUrl:
          "https://media.istockphoto.com/id/584594850/photo/pork-with-pan-fried-vegetables.jpg?s=612x612&w=0&k=20&c=JzSpIvez9hEiG8XLQnA-m11wjq9Rwe-x0w6QnZ4cYe8=",
        name: "Haloumi, Macon &Tomato",
        price: 90,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1213857982/photo/scrambled-eggs-and-fried-bacon-on-plate.jpg?s=612x612&w=0&k=20&c=nPq81Vnw_rnIho_MmcEdLUxgD1CF1B34w70-Dtk0tOE=",
        name: "Macon & Eggs",
        price: 65,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1194279921/photo/cheeses-and-cured-meat-isolated-on-white-background.jpg?s=612x612&w=0&k=20&c=8KZRRnOJsym6ER2anDEvNlkhEV7WTq0YKlTb06CLNAc=",
        name: "Macon & Cheese",
        price: 72,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1450306360/photo/antipasto-board-with-prosciutto-salami-crackers-cheese.jpg?s=612x612&w=0&k=20&c=ng8eFBo_ca_oLA3QE1D1l_l4Na-IcRTbUJFKAZTsKzg=",
        name: "Macon, Cheese, Mushroom & Onion ",
        price: 85,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1152170462/photo/a-lot-of-fresh-fruits-on-white-background.jpg?s=612x612&w=0&k=20&c=7sj9dadZ6AMqY1SYyf8YwP5-mxXM4WCq05outxP6uEQ=",
        name: "SERES WITH FRUITS",
        price: 40,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1152170462/photo/a-lot-of-fresh-fruits-on-white-background.jpg?s=612x612&w=0&k=20&c=7sj9dadZ6AMqY1SYyf8YwP5-mxXM4WCq05outxP6uEQ=",
        name: "SERES PLAIN",
        price: 30,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1293747680/photo/samosa-is-a-famous-street-food-from-india-pakistan-and-bangladesh.jpg?s=612x612&w=0&k=20&c=NiqNCcLLav0pxIKfjxz5PDvhdoPb9t8_N2Zv4zZxN6o=",
        name: "Samosas",
        price: 10,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1820231127/photo/blueberry-french-toast-with-honey-on-wooden-board-isolated-white-background.webp?b=1&s=170667a&w=0&k=20&c=awlov2HmuplZKYdC3-LyFPhpqNeWFKJtQau70fa9VEc=",
        name: "French Toast (2 Slices served with Honey & Chedda",
        price: 80,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1482640372/photo/two-croissants-on-white-background.webp?b=1&s=170667a&w=0&k=20&c=-BP0wVY6LfWz3qVV0wCKI9SFPpcVdCPduVXuWH_rwDU=",
        name: "Croissants (Butter or Nutella or Jam)",
        price: 35,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/472104100/photo/south-indian-food-dosa-with-chutney.jpg?s=612x612&w=0&k=20&c=ZPYOZ3aKdka0ebHSjeAlDgvtlBwG-fsvAupqP4qPuc4=",
        name: "Egg Chapatti Sandwiches",
        price: 75,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/92004820/photo/a-folded-omelets-served-with-herbs-and-tomatoes.webp?b=1&s=170667a&w=0&k=20&c=Q1Sm_SbjkHsAkwHFSjZqp0uJIUasN6hRxM1ci2MRhoc=",
        name: "Mushroom & Cheese Omlettes",
        price: 100,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1302217593/photo/continental-breakfast-scrambled-eggs-with-sausages-and-parmesan.webp?b=1&s=170667a&w=0&k=20&c=WAiKcSelzGevi7VolySnXpyqb0Hzu7_kzcBCJ1MFQY0=",
        name: "Omelettes (With Cheese & Spinach)",
        price: 75,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/916983010/photo/breakfast-sandwich.webp?b=1&s=170667a&w=0&k=20&c=tbWGzr63W2JTgfWg5yr4emOfESzqqvvv9tHwGZ6IQII=",
        name: "Toasted Egg Sandwiches)",
        price: 85,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1499132358/photo/chapati-with-egg-curry.webp?b=1&s=170667a&w=0&k=20&c=6kjSY_UsgzIRFykK8HzCOzaNyaPOikqeeOV6bHcJmwQ=",
        name: "CHAPATI with chiken ",
        price: 75,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1663605445/photo/close-up-fried-fish-and-potato-dish.webp?b=1&s=170667a&w=0&k=20&c=2GJ0fPE2tLQZiOMJGG14QwGrIlIpLfBcVwMoZFOYtbQ=",
        name: "CHIPS MAYAYI",
        price: 35,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/825045916/photo/grilled-sausages-and-vegetables.webp?b=1&s=170667a&w=0&k=20&c=Zb2dV7ftW-6UeqnY6Xje7Qji8bjJO-CIcUVreoLKQ2Q=",
        name: "SAUSAGE WITH CHIPS AND SALADS ",
        price: 55,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/453693787/photo/chapatti-roti-isolated.jpg?s=612x612&w=0&k=20&c=A3-2ophrQ1wHacp5vPBmJWhNS1WBKNWzDM7gzLtmYq4=",
        name: "PLAIN CHAPATI ",
        price: 15,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1299553650/photo/tuna-sandwich.webp?b=1&s=170667a&w=0&k=20&c=IRA9AilVJUwvVkJxR-UF4pasjZz_LRynWO64ytnnSgA=",
        name: "TUNA CHAPATI SANDWHICH",
        price: 75,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/183853817/photo/tuna-sandwich-cut-in-half.webp?b=1&s=170667a&w=0&k=20&c=ncrv86Kf_v0kU4Pui7GkzU3HDMjTV01NMrfmaj_Ncow=",
        name: "TUNA SANDWHICH",
        price: 75,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1411270034/photo/sunny-side-top-up-egg-isolated-on-plain-white-background-top-egg-view.webp?b=1&s=170667a&w=0&k=20&c=e8z0-ou8tf-aqRKIcz-4eVwyur3Ymp6HOIBxft58m-g=",
        name: "PLAIN EGGS",
        price: 5,
        selectedQty: 0,
      },
    ],
    "Cold drinks": [
      {
        imageUrl:
          "https://media.istockphoto.com/id/1402989568/photo/fresh-green-mint-smoothie-isolated-on-white-background-flat-lay.jpg?s=612x612&w=0&k=20&c=iXh0lkHf374eSfj_PPxnQ5PWtUDFaSHUeRnJzmhqGhs=",
        name: "AVACADO YOGHURT SMOOTHIE",
        price: 60,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/138036059/photo/fresh-cocktail-isolated-on-white.jpg?s=612x612&w=0&k=20&c=J9ME-rCYFnDYGsYKn5A0hHq8jgdkuLJg89WxHDZqLMM=",
        name: "Mixed Fruit with ice cream ",
        price: 115,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1480553566/photo/chocolate-banana-cocktail-in-glass-with-chocolate-syrup-white-background.jpg?s=612x612&w=0&k=20&c=b7T2wdexy54daaOBHzJAzgtxDVAsgWa8aV3tDS8rKyw=",
        name: "Banana splite",
        price: 75,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/184377435/photo/hot-chocolate.webp?b=1&s=170667a&w=0&k=20&c=QlahYBaugG9XsWIbtFNh7wHdgojahGZqgTzpCThghJc=",
        name: "chocolate mint",
        price: 75,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/538766570/photo/orange-cocktail.jpg?s=612x612&w=0&k=20&c=ljeL03YN3m81zFiQtiGrc9ZO9841KYoG-p14rCBOF1c=",
        name: "pineapple yoghurt smoothie",
        price: 60,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1490791894/photo/glass-of-tasty-banana-smoothie-with-straw-and-fresh-fruit-on-white-background.jpg?s=612x612&w=0&k=20&c=DPk9kB1KkFYpWINBAUuQ_qndVWwx1CXK8fft16iJDJw=",
        name: "banana yoghurt smoothie",
        price: 60,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/623102446/photo/pomegranate-raspberry-smoothie-in-a-tumbler-glass-isolated-on-white.jpg?s=612x612&w=0&k=20&c=2adtV0w_zFOuYwfen7ypvewKYBLQqGLzgpn-YgDCltI=",
        name: "strawberry yoghurt smoothie",
        price: 60,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1409749018/photo/iced-latte-coffee-on-plastic-glass-and-tube-sucking-isolated-white-background-summer-drink.jpg?s=612x612&w=0&k=20&c=GMZsopCg-V6Ayu0puSuhwl6VcvfIoYwmzqVz5eJy9bw=",
        name: "banana ice smoothie",
        price: 60,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://plus.unsplash.com/premium_photo-1664391890762-032c86019c37?q=80&w=1093&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        name: "strawberry ice smoothie",
        price: 60,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/98215116/photo/cocktail.jpg?s=612x612&w=0&k=20&c=bDa8Xf1NYOlPfucw_BSc8ROZYPypDQPDmeCQXzfs_5c=",
        name: "mixed fruit ice smoothie",
        price: 60,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/486915545/photo/cucumber-celery-mint-apple-pineapple-smoothie.jpg?s=612x612&w=0&k=20&c=IofpPv3cNtqP99ZixC3aYqOcH0JS3U-dELD9nSC8wQM=",
        name: "mint lemonade ice smoothie",
        price: 60,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/619756986/photo/ice-espresso-coffee.jpg?s=612x612&w=0&k=20&c=uKMIxxXJB-zW4rZrN_9byQuuORbbWsccLwevCqVyqXI=",
        name: "iced coffee ice smoothie",
        price: 60,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/681906570/photo/fresh-fruit-juices-on-white.jpg?s=612x612&w=0&k=20&c=ePtQftlPb-188PPrBSBkClo6sYyI92cH1fcWMxShnAg=",
        name: "Mixed fruit fresh juice",
        price: 60,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/483992261/photo/watermelon-smoothie.jpg?s=612x612&w=0&k=20&c=nkYPlT8dxy-m8w40AmYISL7LJOnbD5R8CW4t-kF4Q20=",
        name: "watermelon fresh juice",
        price: 60,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/187661040/photo/orange-juice-and-fruit.jpg?s=612x612&w=0&k=20&c=QUpVW7-Qi3QjMvpG_XJZuAlLoZ7hMpL7eG0Y8qac3RI=",
        name: "orange fresh juice",
        price: 60,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/685277288/photo/cola-with-ice-in-blank-take-away-cup.jpg?s=612x612&w=0&k=20&c=b9hwT3Sc1pAkqxeTc7j9vrS8M8eks0fBPNyzCwhD30I=",
        name: "Take away cup",
        price: 5,
        selectedQty: 0,
      },
    ],

    "Wraps and Pies": [
      {
        imageUrl:
          "https://media.istockphoto.com/id/1360122895/photo/wrap-with-fried-chicken-and-vegetables.jpg?s=612x612&w=0&k=20&c=dOEe4XLgnxU374SlfsA5VtwDa3le_QgiDhMGVM6czEQ=",
        name: "Beef - Wrap",
        price: 75,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1292764346/photo/baked-pork-liver.jpg?s=612x612&w=0&k=20&c=xXyTXiLH4nGp-lKfLeOnzJGWjhtk5ipNLnaW3_U1sZI=",
        name: "Malawa & Liver",
        price: 70,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1687115322/photo/delicious-sweet-baklava-with-walnuts-isolated-on-white-background.jpg?s=612x612&w=0&k=20&c=g-_iCUe3VXSZlpWE132-f3bWlwpUxRQauCQ2hNfu-mI=",
        name: "Chicken - Wrap",
        price: 75,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1813129788/photo/traditional-famous-turkish-dessert-pistachio-pistachio-baklava-on-a-white-plate.jpg?s=612x612&w=0&k=20&c=-uComEgiz_48qmFJhaUbHB7clD6sXqhx7mFASJkG5Oc=",
        name: "Vegetarian - Wrap",
        price: 75,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/174958463/photo/steak-pie.jpg?s=612x612&w=0&k=20&c=SDipDB_b8woUnVkfiW3P4c9ywEfZsSIMowwGlWk2VxQ=",
        name: "Beef Pies ",
        price: 35,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1429394406/photo/french-fries-in-a-white-paper-box-and-fried-chicken-leg-isolated-on-white.jpg?s=612x612&w=0&k=20&c=EhC62P2W6EZhMqla61K4o1k1V_zTANtISDl8Hl99nEQ=",
        name: "Chicken Nuggets with Chips ",
        price: 60,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1134954365/photo/focaccia-and-mushrooms.jpg?s=612x612&w=0&k=20&c=N8-7wHlIiBpbD72aoyI6BS1KL38edNnhWtb7WJtwcvg=",
        name: "Chicken Pies",
        price: 35,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/153835617/photo/cheeseburger-and-french-fries.jpg?s=612x612&w=0&k=20&c=mqwoHXbytSeosF4p5RblhXgXq8ZeqrZXDrbCt66DFDo=",
        name: "Burger n Chips",
        price: 55,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1032847952/photo/french-toast-in-a-white-background.jpg?s=612x612&w=0&k=20&c=nRjDhPWH7mXg8J8IzZ5tU3-fCgyN0e3uJ29g2ORpYew=",
        name: "BREAD WITH EGG",
        price: 35,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/501443225/photo/tuna-sandwich.jpg?s=612x612&w=0&k=20&c=Ad1-BvSPN80TEFY6Be3C1pMgUou-w97nww2WkyLSmWM=",
        name: "BREAD WITH TUNA",
        price: 35,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1565714262/photo/karadjordjes-steak.jpg?s=612x612&w=0&k=20&c=AmpyVkiybcezxmEWOJqlMgLh1xDt7hwtFcpjVoiCyCc=",
        name: "CHICKEN SPRIL ROLL",
        price: 75,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/825793538/photo/high-angel-view-of-french-fries-in-paper-bag.jpg?s=612x612&w=0&k=20&c=LFjkg_yInGSIS2dd2MFvDQPxZf9_Dxgw2zvK6dWRmDo=",
        name: "CHIPS MAYAYA",
        price: 35,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/514359175/photo/club-sandwich.jpg?s=612x612&w=0&k=20&c=YV6A45qJxjLuP30S5COz7ujcz9l9tOc95XEEVq_ldTs=",
        name: "BREAD WITH CHICKEN",
        price: 35,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/934301328/photo/pasta-with-meat-carrots-and-basil-isolated-on-white-background.jpg?s=612x612&w=0&k=20&c=fg8olg-2RMwMl2meGuH55N1tcUTZ28l2GkwQdFlzWJg=",
        name: "saldata beef and spaghetti",
        price: 75,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/120720640/photo/spaghetti-pasta-with-cheese-and-tomato-sauce.jpg?s=612x612&w=0&k=20&c=UMEDdTe03IaHzaav5Wraw_whEVKcyRFrl80AabOenEo=",
        name: "saldata chicken and spaghetti",
        price: 75,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/156677307/photo/popcorn-chicken.jpg?s=612x612&w=0&k=20&c=z2OW-VlcR0UOtl3VsZo0Zt9oz0SvjHV5cCNTBg27b0A=",
        name: "CHICKEN NUGGETS PLAIN",
        price: 40,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1222242464/photo/wrap-sandwich-shish-taouk-curry-tandoor-on-white-background.jpg?s=612x612&w=0&k=20&c=9hmTGhu7Isy0QFzlF0qorNFARkQaYEJZbBMZcYr7YZE=",
        name: "Chappati & Goat Meat",
        price: 65,
        selectedQty: 0,
      },
    ],

    "Sweet Stuff": [
      {
        imageUrl:
          "https://media.istockphoto.com/id/638670372/photo/stack-of-pancakes-on-plate-isolated.jpg?s=612x612&w=0&k=20&c=zPPqPNNVI4xUIjn-_bE26Ja3rN15W0jH-1boUVvVSeQ=",
        name: "Apple & Cinnamon Pancake",
        price: 65,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/810147810/photo/fruit-bowl-isolated.jpg?s=612x612&w=0&k=20&c=F2zDR2U5EHDK_FUWGjE3mkhTHuZlupmMHsl1kLLjSJg=",
        name: "fruit bowl medium ",
        price: 95,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/460488149/photo/dark-chocolate-cake.jpg?s=612x612&w=0&k=20&c=6hrgS5DIiFeXB3N0YG5ybCZ38F9MhaHB8JKXa0tstqc=",
        name: "Chocolate Cake(slice)",
        price: 30,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1078256544/photo/two-glazed-donuts.jpg?s=612x612&w=0&k=20&c=UpDgcOZmw0trLzIp0dZ7On5I1lDRxuZwQtGA1j8qiDE=",
        name: "Cream Doughnuts",
        price: 15,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1217988121/photo/mini-tasty-cupcake-isolated-on-white-background.jpg?s=612x612&w=0&k=20&c=jvRH09yiyikDEoSo_GroadIZJS9BKY9moW1VMPV21YE=",
        name: "Cup Cakes",
        price: 15,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/598064864/photo/vanilla-and-chocolate-chip-muffin.jpg?s=612x612&w=0&k=20&c=4FDkCwIT3IgFfcgoHP0-IZksf3vuCcq3JFeZM9D3sFQ=",
        name: "Muffins",
        price: 20,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/157558423/photo/pancakes.jpg?s=612x612&w=0&k=20&c=zgtJ9cNgWIcjLkX6CoMQmAGkeGAcOPMLGXmZTr93SG4=",
        name: "Pancakes with Nutella& Honey",
        price: 80,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/489481196/photo/donut-on-white-background.jpg?s=612x612&w=0&k=20&c=T01BYqL-VsuGjL660Vuu62vUJg-2JX4-jWBWY3QAqKM=",
        name: "Plain doughnuts",
        price: 10,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1441061045/photo/piece-of-cheesecake-with-raspberries-and-mint-isolate-on-a-white-background.jpg?s=612x612&w=0&k=20&c=dQ7Tsp5BT-CGhVEbWVDkaSuH6Ggp_5cM3jOsR8czRi4=",
        name: "Plain Slice Cakes",
        price: 50,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/480125989/photo/yam-roll.jpg?s=612x612&w=0&k=20&c=Z7K22nEOOcCuH2vkAHxrMs_zTrth0MBDw6Lm_XQ_Gik=",
        name: "Swiss Rolls",
        price: 40,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/516250699/photo/almond-cake.jpg?s=612x612&w=0&k=20&c=ugVD19i-gTuy8oPqMhS4VDSmjnvpm0EXhPbx4G46tyE=",
        name: "Vanilla Cake(slice)",
        price: 60,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/183344973/photo/triangular-piece-of-cake-over-white-background.jpg?s=612x612&w=0&k=20&c=_upZBF-aAWNUVUYkirkVhHWt9HJx330BfLJXxkYfxxA=",
        name: "Vanilla/Caramel/Butterscotch Dream(slice)",
        price: 60,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/177709302/photo/heap-of-the-waffles-on-white.jpg?s=612x612&w=0&k=20&c=oXvqvw6E-ykHXEDCbn--Jooz4iMco6X4Rw7P5kxNB-s=",
        name: "Waffles Plain",
        price: 60,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1293544493/photo/waffle.jpg?s=612x612&w=0&k=20&c=WZcvjOcteIclChx6_R5BiwjDtMd5vEg4NOkHZjQ1MZ8=",
        name: "Waffles with Nutella or Honey ",
        price: 60,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/607267898/photo/foy-thong-chiffon-cake-isolated-on-white-background.jpg?s=612x612&w=0&k=20&c=IggXoLEZ4hg1zL1Yp1e7NQOSNiFimk7arsP2BH6W54A=",
        name: "big plain vanilla cake",
        price: 10,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1138316395/photo/almond-cookies.jpg?s=612x612&w=0&k=20&c=Ui0HpAVZqVYq1jAU1cr5FTfxt92ylAj2k8Ob9ceFzD4=",
        name: "VANILLA SUGAR COOKIES",
        price: 12,
        selectedQty: 0,
      },
      {
        imageUrl:
          "https://media.istockphoto.com/id/1134897271/photo/biscuits-with-chocolate-isolated-on-white-background.jpg?s=612x612&w=0&k=20&c=lzX92wxN5mwL0ZsQndE_XubR0h_g3fyMgy0sKMQIAtg=",
        name: "CHOCOLATE SUGAR COOKIES",
        price: 12,
        selectedQty: 0,
      },
    ],
  };
  const handleSelectCategory = (category) => {
    setSelectedCategory(category);
    handleShow();
  };

  function generateRandomText(length) {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let randomText = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * alphabet.length);
      randomText += alphabet.charAt(randomIndex);
    }

    return randomText;
  }

  const fetchData_ordernum = async () => {
    try {
      const randomValue = Math.floor(Math.random() * 1000); // Adjust the
      const randomText = generateRandomText(5);

      const response = await fetch(
        `${apiUrl}/ordernum.php?${randomText}=${randomValue}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const jsonData = await response.json();
      if (jsonData === "" || jsonData === null) {
        setData("...loading");
      } else {
        setData(jsonData);
      }

      console.log(
        "the data from the use effect" + JSON.stringify(jsonData, null, 2)
      );
    } catch (error) {
      console.error("There was a problem fetching the data:", error);
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      // Run your code only on the initial mount
      fetchData_ordernum();
      isMounted.current = false;
    }
  }, []);

  const handleAddToCart = (item) => {
    const itemInCart = cartItems.find((x) => x.name === item.name);

    console.log("item availability: " + itemInCart);

    if (itemInCart !== undefined) {
      //update qty

      let newcartItems = cartItems;

      newcartItems.map((x) => {
        if (x.name === newcartItems.name) {
          x.count = x.count + 1;
          newcartItems.count = newcartItems.count + 1;
        }
      });

      console.log(newcartItems);

      setCartItems(newcartItems);
    } else {
      //add new
      item.count = 1;
      setCartItems((prevCartItems) => [...prevCartItems, item]);
    }
  };

  const handleRemoveFromCart = (item) => {
    setCartItems((prevCartItems) =>
      prevCartItems.filter((cartItem) => cartItem !== item)
    );
  };
  function generateRandomText(length) {
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let randomText = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * alphabet.length);
      randomText += alphabet.charAt(randomIndex);
    }

    return randomText;
  }

  const fetchData = async () => {
    const randomValue = Math.floor(Math.random() * 1000); // Adjust the
    const randomText = generateRandomText(5);
    try {
      const response = await fetch(
        `${apiUrl}/productsMenu.php?${randomText}=${randomValue}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const jsonData = await response.json();
      setMenuItems(jsonData);
      console.log("this is the data", jsonData);
    } catch (error) {
      console.error("There was a problem fetching the data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container style={{ alignContent: "center" }}>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedCategory}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MenuItemList
            items={menuItems[selectedCategory] || []}
            onAddToCart={handleAddToCart}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Row style={{ marginBottom: "20px" }}>
        <Col className="text-center" style={{ marginTop: 20 }}>
          <Image src="/home/images/logo.png" width={150} height={100} />
        </Col>
      </Row>
      <Row style={{ marginBottom: "5px" }}>
        <Col className="text-center">
          <h3>Meal Ordering System</h3>
          <p>
            Click any of the categories below to select order items. You can
            select from more than one category.
          </p>
        </Col>
      </Row>
      <Row style={{ marginBottom: "20px" }}>
        <Col className="text-center">
          <Button
            variant="primary"
            size="lg"
            onClick={() => handleSelectCategory("Hot Beverages")}
            style={{ marginBottom: "5px" }}
            disabled={isButtonDisabled}
          >
            <FontAwesomeIcon icon={faCoffee} /> Hot Bevarages{" "}
          </Button>{" "}
          <Button
            variant="secondary"
            size="lg"
            onClick={() => handleSelectCategory("Breakfast")}
            style={{ marginBottom: "5px" }}
            disabled={isButtonDisabled}
          >
            <FontAwesomeIcon icon={faBreadSlice} /> Breakfast{" "}
          </Button>{" "}
          <Button
            variant="success"
            size="lg"
            onClick={() => handleSelectCategory("Cold Drinks")}
            style={{ marginBottom: "5px" }}
            disabled={isButtonDisabled}
          >
            <FontAwesomeIcon icon={faGlassWater} disabled={isButtonDisabled} />{" "}
            Cold drinks
          </Button>{" "}
          <Button
            variant="warning"
            size="lg"
            onClick={() => handleSelectCategory("Wraps and Pies")}
            style={{ marginBottom: "5px" }}
            disabled={isButtonDisabled}
          >
            <FontAwesomeIcon icon={faBowlFood} /> Wraps and Pies
          </Button>{" "}
          <Button
            variant="danger"
            size="lg"
            onClick={() => handleSelectCategory("Sweet Stuff")}
            style={{ marginBottom: "5px" }}
            disabled={isButtonDisabled}
          >
            <FontAwesomeIcon icon={faIceCream} /> Sweet Stuff
          </Button>{" "}
          {/* <Link to="/imagemanager">Image Manager</Link> */}
          {/*
            <Button variant="info">Info</Button>{" "}
            <Button variant="light">Light</Button>{" "}
            <Button variant="dark">Dark</Button>
        */}
        </Col>
      </Row>

      <Row>
        <Col md={3}></Col>
        <Col md={6}>
          <ShoppingCart
            cartItems={cartItems}
            onRemoveFromCart={handleRemoveFromCart}
            onOrderConfirmation={fetchData_ordernum}
            tableData={tableId}
            orderNumData={data}
            onButtonClick={handleButtonClick}
            onButtonClick2={handleButtonClick2}
            onConfirmOrder={handleConfirmOrder}
            onMakeAnotherOrder={handleMakeAnotherOrder}
            buttonsDisabled={buttonsDisabled}
          />
          {/*
             <MenuItemList
            items={menuItems[selectedCategory] || []}
            onAddToCart={handleAddToCart}
          />

            */}
        </Col>
        <Col md={3}>
          {/*
            <ShoppingCart
            cartItems={cartItems}
            onRemoveFromCart={handleRemoveFromCart}
          />
            */}
        </Col>
      </Row>
    </Container>
  );
};
export default CustomerMenu;
